import { slides } from "../../../../config/main-slider"

interface Props{
    slideNumber: number
    lazy?: boolean
}


const SlideTwo = (props:Props) => {
    return (
        <div className = {`carousel-item`}>
            <img src = {slides[props.slideNumber].img} alt = {slides[props.slideNumber].img_alt} className = "carousel-image" loading = {props.lazy? 'lazy' : 'eager'}/>
            <div 
                className = "carousel-item-card card-two"
                style={{
                    display: 'flex'
                }}
            >
                <h2>
                    Акция:
                </h2>
                <h3>
                    Отбеливание <b>ZOOM4</b> со скидкой 30%
                </h3>
                <h4>
                    <b>Стоимость 40 000 руб.</b>
                </h4>
            </div>
        </div>
    )
}

export default SlideTwo